import Swiper, {Navigation, Pagination} from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function slider() {
    const floorsSlider = new Swiper('.JS-floors-slider', {
        modules: [Navigation],
        slidesPerView: 1,
        loop: true,
        keyboard: {
            enabled: true,
            onlyInViewport: true,
        },
        navigation: {
            prevEl: '.JS-floors-prev',
            nextEl: '.JS-floors-next',
        },
    });

    const spaceplanSlider = new Swiper('.JS-spaceplan-slider', {
        modules: [Navigation],
        slidesPerView: 1,
        loop: true,
        keyboard: {
            enabled: true,
            onlyInViewport: true,
        },
        navigation: {
            prevEl: '.JS-spaceplan-prev',
            nextEl: '.JS-spaceplan-next',
        },
    });
}
