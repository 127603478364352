export default function tabs() {
    const tabs = document.querySelector('.JS-tabs');

    if (!tabs) {
        return;
    }

    const tabsList = tabs.querySelector('.JS-tabs-list');

    if (!tabsList) {
        console.warn('No tabs list found.');
        return;
    }

    const tabsButtons = tabsList.querySelectorAll('a');
    const tabsPanels = tabs.querySelectorAll('.tabs__content > div');

    tabsButtons.forEach(tabsButton => {
        tabsButton.addEventListener('click', event => {
            event.preventDefault();

            const activePanelId = tabsButton.getAttribute('href');
            const activePanel = tabs.querySelector(activePanelId);

            tabsButtons.forEach(tabsButton => {
                tabsButton.classList.remove('is-active');
            });

            tabsButton.classList.add('is-active');

            tabsPanels.forEach(tabsPanel => {
                tabsPanel.classList.remove('is-active');
            });

            activePanel.classList.add('is-active');
        });
    });
}
