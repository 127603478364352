export default function navigation() {
    const siteNavigation = document.getElementById('JS-primary-nav');

    if (!siteNavigation) {
        console.warn('No site navigation found.');
        return;
    }

    const menu = siteNavigation.getElementsByTagName('ul')[0];

    if ('undefined' === typeof menu) {
        console.error('Notice: No menu list found.');
        return;
    }

    const menuToggle = document.createElement('button');

    menuToggle.setAttribute('type', 'button');
    menuToggle.setAttribute('aria-controls', 'JS-nav-primary');
    menuToggle.setAttribute('aria-label', 'Otwórz menu');
    menuToggle.classList.add('nav__toggle');

    menu.parentNode.insertBefore(menuToggle, menu);

    menuToggle.addEventListener('click', function () {
        menu.classList.toggle('is-visible');
        menuToggle.classList.toggle('is-expanded');
        document.body.classList.toggle('body-nav-expanded');

        if (menuToggle.getAttribute('aria-expanded') === 'true') {
            menuToggle.setAttribute('aria-expanded', 'false');
        } else {
            menuToggle.setAttribute('aria-expanded', 'true');
        }
    });
}
