export default class FilteredMap {
  constructor() {
    if (!this.vars()) return false;

    this.setupFilteredMap();
  }

  vars() {
    this.selectors = {
      map: '#filtered-map',
      wrapper: '.JS-map-categories-wrapper',
      categories: '.JS-map-categories',
    };

    this.map = document.querySelector(`${this.selectors.map}`);

    if (!this.map) return false;

    this.wrapper = document.querySelector(`${this.selectors.wrapper}`);
    this.categories = this.wrapper.querySelectorAll(`${this.selectors.categories}`);
    this.markers = [];


    this.locations = window.locations;

    return true;
  }

  setupFilteredMap() {
    var map = new google.maps.Map(document.getElementById('filtered-map'), {
      zoom: 16,
      center: new google.maps.LatLng(52.233823817195244, 21.111161986017425),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [{ "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e9e9e9" }, { "lightness": 17 }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 20 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }, { "lightness": 17 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 }] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 18 }] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 16 }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 21 }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#dedede" }, { "lightness": 21 }] }, { "elementType": "labels.text.stroke", "stylers": [{ "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 }] }, { "elementType": "labels.text.fill", "stylers": [{ "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "geometry", "stylers": [{ "color": "#f2f2f2" }, { "lightness": 19 }] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "color": "#fefefe" }, { "lightness": 20 }] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 }] }]
    });

    var marker,
        infoWindow = new google.maps.InfoWindow();

    for (var i = 0; i < Object.keys(this.locations).length; i++) {
      var locations = this.locations[Object.keys(this.locations)[i]];

      for (var j = 0; j < locations.length; j++) {
        var location = locations[j];

        marker = new google.maps.Marker({
          position: new google.maps.LatLng(location[0], location[1]),
          icon: location[2],
          map: map,
          category: Object.keys(this.locations)[i],
          title: location[3],
          distance: location[4],
        });

        this.markers.push(marker);
      }
    }

    this.markers.forEach(mark => {
      google.maps.event.addListener(mark, 'click', () => {
        if (mark.category == 'symbiocity') return;

        var content =
          '<div class="">' +
            '<div class="">' +
            mark.title +
            '</div>' +
            // '<div class="">' +
            //   mark.distance +
            // '</div>' +
          '</div>';

        infoWindow.setContent(content);
        infoWindow.open({
          anchor: mark,
          map,
        });
      });
    });

    for (var m = 0; m < this.markers.length; m++) {
      var marker = this.markers[m]

      for (var i = 0; i < this.categories.length; i++) {
        var item = this.categories[i];

        item.addEventListener('change', () => {
          var checkedCheckboxes = Array.from(this.wrapper.querySelectorAll(`input[type="checkbox"]:checked`)),
              checkedIds = checkedCheckboxes.map((item) => item.value);

          for (var n = 0; n < this.markers.length; n++) {
            var markerEl = this.markers[n];

            if (markerEl.category != 'symbiocity') {
              markerEl.setVisible(false);
            }
          }

          for (var f = 0; f < this.filterMarkers(checkedIds).length; f++) {
            var el = this.filterMarkers(checkedIds)[f];

            el.setVisible(true);
          }
        });
      }
    }
  }

  filterMarkers(categories) {
    var arr = [];

    for (var m = 0; m < this.markers.length; m++) {
      var marker = this.markers[m];

      if (categories.indexOf(marker.category) !== -1) {
        arr.push(marker);
      }
    }

    return !categories.length ? this.markers : arr;
  }
}
