import FilteredMap from './classes/FilteredMap.js';

import navigation from './partials/_navigation.js';
import slider from './partials/_slider.js';
import tabs from './partials/_tabs.js';
import gLightbox from 'glightbox';

document.addEventListener('DOMContentLoaded', function () {
    new FilteredMap();

    navigation();
    slider();
    tabs();

    const lightbox = gLightbox();
    lightbox.on('open', (target) => {
        console.log('lightbox opened');
    });

});